import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent !== 'given') {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'given');
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className='cookie-container'>
             <img src='/assets/cookies.webp' alt='Cookie' />
            <div>
                <h2>We are the Cookies on <br/> BasedShremp! 🍪</h2>
                <p>Cookies help us understand how you interact with our content and show you things that are totally Shremped out! By clicking "Accept," you agree to our use of cookies for these purposes.</p>
                <span onClick={handleAccept}>
                    Accept cookies
                </span>
            </div>
           
           
        </div>
    );
};

export default CookieConsent;