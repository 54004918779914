import OneSignal from 'react-onesignal';

const initOneSignal = async () => {
  await OneSignal.init({
    appId: '3c86aa1e-c78b-464a-9e43-664181171842',
  });

  OneSignal.showSlidedownPrompt().then(() => {
    console.log('OneSignal prompt shown');
  }).catch((e) => {
    console.log('Error showing OneSignal prompt', e);
  });
};

export default initOneSignal;

//4d8d2290-3fbd-4bc3-aa59-304ff00b430f
//3c86aa1e-c78b-464a-9e43-664181171842