import React, {useEffect, Suspense, lazy, useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import initOneSignal from './components/OneSignal';
import { Bars } from 'react-loader-spinner';

const Home = lazy(() => import('./pages/home/home')); 
const Kyc = lazy(() => import( './pages/kyc/kyc'));
const Legal = lazy(() => import('./pages/legal/legal'));
const LegalJuridic = lazy(() => import('./pages/legal-juridic/legal-juridic'));
const BlogPost = lazy(() => import('./pages/blog-post/blog-post'));
const Roadmap = lazy(() => import('./pages/roadmap/roadmap'));
const WhitePaper = lazy(() => import('./pages/white-paper/white-paper'));
const BlogsSection = lazy(() => import('./pages/blog/blog'));
const Game = lazy(() => import('./pages/game/Game'));
const ComingSoon = lazy(() => import('./pages/coming-soon/coming-soon'));
const PresaleHome = lazy(() => import('./pages/presale-home/presale-home'));
const PresaleHomePrivate = lazy(() => import('./pages/presale-home-private/presale-home-private'));
const PresaleHomePrivateTasks = lazy(() => import('./pages/presale-home-private-tasks/presale-home'));
const Whitelisting = lazy(() => import('./pages/whitelisting/whitelisting'))
// const I1 = lazy(() => import('./pages/private-task-redirects/I1'))
const I2 = lazy(() => import('./pages/private-task-redirects/I2'))
const I3 = lazy(() => import('./pages/private-task-redirects/I3'))
const I6 = lazy(() => import('./pages/private-task-redirects/I6'))

// const Presale = lazy(() => import('./pages/presale/presale'));
// import NFTChecker2 from './pages/test/test2';


function App() {


  useEffect(() => {
    initOneSignal();
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={<div className='page-load'><Bars color='white'/></div>}>
        <Routes>
          <Route path='/' element={<PresaleHome />} />
          <Route path='/private' element={<PresaleHomePrivate />} />
          <Route path='/private-rewards' element={<PresaleHomePrivateTasks />} />
          <Route path='/light-check' element={<Kyc />} />
          <Route path='/legal' element={<Legal />} />
          <Route path='/legal-juridic' element={<LegalJuridic />} />
          <Route path='/blog-post' element={<BlogPost />} />
          <Route path='/roadmap' element={<Roadmap />} />
          <Route path='/white-paper' element={<WhitePaper />} />
          <Route path='/blog' element={<BlogsSection />} />
          <Route path='/game' element={<Game />} />
          <Route path='/coming-soon' element={<ComingSoon />} />
          <Route path='/whitelisting' element={<Whitelisting />} />
          {/* <Route path='/StevenAscher' element={<I1 />} /> */}
          <Route path='/CryptoKevv' element={<I2 />} />
          <Route path='/bySukie' element={<I3 />} />
          <Route path='/Scrambller' element={<I6 />} />
          {/* <Route path='/test2' element={<NFTChecker2 />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>     
  );
}


export default App;

// Deployment ID: AKfycbxGCWPytOHPBXWBm5xR5EZhqKbAWx8kURspCgUdIxvnKQuwYYVNmgjJdiaZ59stOLdleg
// Web App: https://script.google.com/macros/s/AKfycbxGCWPytOHPBXWBm5xR5EZhqKbAWx8kURspCgUdIxvnKQuwYYVNmgjJdiaZ59stOLdleg/exec

// Google Sheet: https://docs.google.com/spreadsheets/d/1LlLijUcPukqPmxM_6HyiMUm6xtB3ijw_x9WxUOLwh4g/edit?gid=0#gid=0
// Google Script: https://script.google.com/u/6/home/projects/1IXDNdywB84vhZpJDkGVckzZDb80d_8a2K25-7aNs1lX4LJ2NTm81G625/edit
